<template>
  <el-card class="card">
    <div slot="header" class="clearfix">
      <span class="card-header-title">报名中培训班</span>
      <el-button class="view-more" type="text" @click="viewMore">
        <span>查看更多</span>
        <i class="el-icon-arrow-right el-icon--right"></i>
      </el-button>
    </div>
    <div v-for="item in items" :key="item.uuid" class="item">
      <div class="left" :style="{ backgroundImage: `url(${file}${item.activityCoverPath})` }">
        <div class="label">已报名</div>
        <div class="value-wrapper">
          <span class="value">{{ item.traineeListCount || 0 }}</span>
          <span class="unit">人</span>
        </div>
      </div>
      <div class="right">
        <div class="title">{{ item.trainingActivityName || '-' }}</div>
        <div v-if="item.registStartTime" class="time">报名时间：{{ item.registStartTime?.split(' ')[0] }}至{{ item.registEndTime?.split(' ')[0] }}</div>
        <div v-if="item.offLineStartTime" class="time">线下培训：{{ item.offLineStartTime?.split(' ')[0] }}至{{ item.offLineEndTime?.split(' ')[0] }}</div>
      </div>
    </div>
  </el-card>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      api: this.$store.state.api,
      file: this.$store.state.file,
    }
  },
  computed: {
    items() {
      return this.data?.applyingActivityMainList || []
    },
  },
  methods: {
    viewMore() {
      this.$router.push({ name: 'TrainingManagement' })
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .el-card__body {
  max-height: calc(100% - 48px);
  overflow: auto;
}
.card {
  flex-grow: 1;
  min-height: 549px;
  background: #ffffff;
  border-radius: 2px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12), 0px 0px 6px 0px rgba(0, 0, 0, 0.04);
}
.item {
  display: flex;
  + .item {
    margin-top: 16px;
  }
  .left {
    position: relative;
    left: 0;
    flex-shrink: 0;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 4px;
    width: 108px;
    height: 73px;
    background-position: center;
    background-size: cover;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.4);
    }
    .label {
      margin-bottom: 3px;
      font-size: 14px;
      //font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
      color: #ffffff;
      line-height: 14px;
      z-index: 1;
    }
    .value-wrapper {
      display: flex;
      z-index: 1;
      .value {
        margin-right: 4px;
        font-size: 28px;
        //font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
        color: #ffe84a;
        line-height: 32px;
      }
      .unit {
        font-size: 14px;
        //font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
        color: #ffe84a;
        line-height: 14px;
        transform: translateY(14px);
      }
    }
  }
  .right {
    min-width: 0;
    .title {
      margin-bottom: 12px;
      font-size: 16px;
      //font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
      font-weight: bold;
      color: #3a485e;
      line-height: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .time {
      margin-top: 10px;
      font-size: 14px;
      //font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
      color: #888888;
      line-height: 14px;
    }
  }
}
</style>
