<template>
  <el-card class="card">
    <div slot="header" class="clearfix">
      <span class="card-header-title">招生情况</span>
      <el-button class="view-more" type="text" @click="viewMore">
        <span>查看更多</span>
        <i class="el-icon-arrow-right el-icon--right"></i>
      </el-button>
    </div>
    <el-table row-key="zoneCode12" :data="tableList" :border="false" :stripe="false" tooltip-effect="light">
      <el-table-column prop="index" label="排名" width="50" header-align="center" align="center">
        <template slot-scope="scope">
          <span :class="['index', ['one', 'two', 'three'][scope.$index] || '']">
            {{ scope.$index + 1 }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="zoneName" label="街道名称" min-width="110" header-align="left" align="left">
        <template slot-scope="scope">
          <span>{{ scope.row.zoneName || '-' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="traineeListCount" label="本月招生人数" min-width="110" header-align="center" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.traineeListCount || '-' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="unitTraineeListCount" label="本月企业报名数" min-width="120" header-align="center" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.unitTraineeListCount || '-' }}</span>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  computed: {
    tableList() {
      return this.data?.traineeListCountListTop10 || []
    },
  },
  methods: {
    viewMore() {
      this.$router.push({ name: 'StudentHours' })
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .el-card__body {
  padding: 0 !important;
}
/deep/ .el-table {
  &::before {
    display: none;
  }
  .el-table__cell {
    padding: 12px 0 8px;
  }
  td.el-table__cell,
  th.el-table__cell.is-leaf {
    border-bottom: 0;
  }
}
/deep/ .el-table th.el-table__cell > .cell {
  padding-left: 6px;
  padding-right: 6px;
  color: #777f89;
  font-weight: normal;
}
/deep/ .el-table .cell {
  padding-left: 6px;
  padding-right: 6px;
}
/deep/ .el-table__header-wrapper {
  .cell {
    font-size: 14px;
    //font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
    color: #777f89;
  }
}
/deep/ .el-table__body-wrapper {
  .cell {
    font-size: 16px;
    //font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
    color: #191919;
  }
}
.card {
  flex-grow: 1;
  min-height: 549px;
  background: #ffffff;
  border-radius: 2px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12), 0px 0px 6px 0px rgba(0, 0, 0, 0.04);
}
.index {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  font-size: 16px;
  //font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
  color: #777f89;
  line-height: 16px;
  &.one {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(~@/views/trainingInstitutionHome/images/ic_one.png);
    color: transparent;
  }
  &.two {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(~@/views/trainingInstitutionHome/images/ic_two.png);
    color: transparent;
  }
  &.three {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(~@/views/trainingInstitutionHome/images/ic_three.png);
    color: transparent;
  }
}
</style>
