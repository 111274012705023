<template>
  <base-dialog ref="dialog" class="admin-add-dialog" title="信息添加" @determine="submit" @cancel="cancel" @close="close">
    <div class="content">
      <el-form class="ms-form" ref="msForm" :model="msForm" :rules="rules" label-position="right" label-width="110px">
        <el-form-item label="姓名" prop="userName" :rules="rules.userName">
          <el-input class="zwx-input" placeholder="请输入" style="width: 200px !important;" v-model.trim="msForm.userName" maxlength="25" clearable />
        </el-form-item>
        <el-form-item label="手机号" prop="mobileTel" :rules="rules.mobileTel">
          <el-input class="zwx-input" placeholder="请输入" style="width: 200px !important;" v-model.trim="msForm.mobileTel" maxlength="20" clearable />
        </el-form-item>
        <el-form-item label="账号" prop="userNo" :rules="rules.userNo">
          <el-input class="zwx-input" placeholder="请输入" style="width: 200px !important;" v-model.trim="msForm.userNo" maxlength="25" clearable />
        </el-form-item>
        <el-form-item label="证件类型" prop="cardType" >
          <el-select class="zwx-input" v-model="msForm.cardType" style="width:200px !important;">
            <el-option v-for="item in cardList" :key="item.value" :value="item.value" :label="item.label"></el-option>
          </el-select>
          <!-- <el-input class="zwx-input" placeholder="请输入" style="width: 200px !important;" v-model.trim="msForm.userName" maxlength="25" clearable /> -->
        </el-form-item>
        <el-form-item label="证件号码" prop="idc" >
          <el-input class="zwx-input" placeholder="请输入" style="width: 200px !important;" v-model.trim="msForm.idc" maxlength="25" clearable />
        </el-form-item>
        <el-form-item label="性别" prop="userSex" >
          <el-select class="zwx-input" v-model="msForm.userSex" style="width:200px !important;">
            <el-option v-for="item in sexList" :key="item.value" :value="item.value" :label="item.label"></el-option>
          </el-select>
       </el-form-item>
      </el-form>
    </div>
  </base-dialog>
</template>

<script>
export default {
  props: {
    techServiceBasic: {
      type: Object,
      required: true,
    },
    sexList:{
      type:Array,
      default:()=>[]
    },
    cardList:{
      type:Array,
      default:()=>[]
    },
  },
  data() {
    return {
      api: this.$store.state.api,
      users: this.$store.state.users,
      rules: {
        userName: [{ required: true, message: '请填写姓名', trigger: ['change', 'blur'] }],
        mobileTel: [
          { required: true, message: '请填写手机号', trigger: ['change', 'blur'] },
          { required: true, validator: this.$validate.mobilePhone, trigger: ['change', 'blur'] },
        ],
        userNo: [{ required: true, message: '请填写账号', trigger: ['change', 'blur'] }],
        idc:[ { validator: this.validatorIdCard, trigger: 'blur' },]
      },
      msForm: {
        userName: undefined,
        mobileTel: undefined,
        userNo: undefined,
        userSex:undefined,
        idc:undefined,
        cardType:undefined
      },
    }
  },
  methods: {
      /**
     * 身份证号校验
     */
     validateIdCode(rule, value, callback) {
      let IdType = this.msForm.cardType
      if (IdType === '' || IdType === null || IdType === undefined) {
        return callback(new Error('请先选择证件类型'))
      }
      if (IdType === '10030001') {
        return this.$validate.idcCode(rule, value, val => {
          if (this.$zwxBase.verifyIsBlank(val)) {
            return callback()
          } else {
            return callback(new Error('请输入正确的身份证号码'))
          }
        })
      }
      return callback()
    },
    cancel() {
      this.$refs.dialog.show(false)
    },
    close() {
      this.$refs.msForm.resetFields()
    },
    setData(data){
      this.msForm=data
    },
    init(){
      this.msForm={
        userName: undefined,
        mobileTel: undefined,
        userNo: undefined,
        userSex:undefined,
        idc:undefined,
        cardType:undefined
      }
      this.$nextTick(()=>{
        if( this.$refs.msForm){
          this.$refs.msForm.clearValidate()
        }
      })
      // this.$refs.msForm.resetFields()
    },
    submit() {
      // console.log(this.sexList)
      // return
      this.$refs.msForm.validate(valid => {
        if (valid) {
          this.saveData()
        }
      })
    },
    saveData() {
      this.$emit('loading', true)
      let data = {
        unitUid: this.techServiceBasic.unitUid,
        
        tsUserInfoExp: {
          ...this.msForm,
          mobileTel: this.$zwxSm.sm4JsEncrypt(this.msForm.mobileTel),
          userNo: this.$zwxSm.sm4JsEncrypt(this.msForm.userNo),
        },
      }
      this.$system.postJson(
        this.api + '/basic/info/addOrModifyServiceUser-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.$refs.dialog.show(false)
            this.$system.notify('成功', data.mess, 'success')
            this.$emit('update')
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
            this.$emit('loading', false)
          } else {
            this.$notify.error({ title: '错误', message: data.mess })
            this.$emit('loading', false)
          }
        },
        () => {
          this.$emit('loading', false)
          this.$notify.error({ title: '错误', message: '网络连接失败' })
        }
      )
    },
  },
}
</script>

<style lang="less" scoped>
.content {
  padding: 40px 48px 20px 60px;
  box-sizing: border-box;
}
.admin-add-dialog {
  /deep/ .el-dialog {
    width: 500px !important;
    min-width: 500px !important;
  }
}
/deep/ .zwx-form {
  // padding-right: 0 !important;
  .el-form-item {
    margin-right: 0 !important;
  }
}
</style>
