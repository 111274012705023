var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: {
        margin: "0",
        "padding-bottom": "0px",
        "border-top": "1px solid #e1e5f4"
      }
    },
    [
      _c(
        "base-headline",
        {
          staticClass: "no-top no-bottom",
          attrs: { title: _vm.isTown ? "用户信息" : "管理员信息列表" }
        },
        [
          _vm.ifEdit
            ? _c(
                "el-button",
                {
                  staticClass: "zwx-button zwx-button-icontext-28",
                  attrs: { icon: "el-icon-plus" },
                  on: { click: _vm.addInfo }
                },
                [_vm._v("添加")]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "max-width": "9999px" } },
        [
          _c(
            "el-form",
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-bottom": "0 !important" },
                  attrs: {
                    label: "",
                    "label-width": "0",
                    prop: "userInfoExpList"
                  }
                },
                [
                  _c(
                    "el-table",
                    {
                      staticClass: "zwx-table",
                      attrs: {
                        data: _vm.configList,
                        border: "",
                        stripe: "",
                        "tooltip-effect": "light"
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "userName",
                          label: "姓名",
                          width: "240",
                          "header-align": "center",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: `userInfoExpList[${scope.$index}].userName`,
                                      rules: _vm.rules.userName
                                    }
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.userName || "-"))
                                    ])
                                  ]
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "userName",
                          label: "性别",
                          width: "140",
                          "header-align": "center",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(scope.row.userSexName))
                                ])
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "userName",
                          label: "证件类型",
                          width: "240",
                          "header-align": "center",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(scope.row.cardTypeName))
                                ])
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "idc",
                          label: "证件号",
                          width: "240",
                          "header-align": "center",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("span", [_vm._v(_vm._s(scope.row.idc))])
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "mobileTel",
                          label: "手机号",
                          width: "240",
                          "header-align": "center",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: `userInfoExpList[${scope.$index}].mobileTel`,
                                      rules: _vm.rules.mobileTel
                                    }
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.mobileTel || "-"))
                                    ])
                                  ]
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm.ifEdit
                        ? _c("el-table-column", {
                            attrs: {
                              prop: "userNo",
                              label: "账号",
                              width: "240",
                              "header-align": "center",
                              align: "center"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: `userInfoExpList[${scope.$index}].userNo`,
                                            rules: _vm.rules.userNo
                                          }
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(scope.row.userNo || "-")
                                            )
                                          ])
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1288744459
                            )
                          })
                        : _c("el-table-column", {
                            attrs: {
                              prop: "userNo",
                              label: "账号",
                              "min-width": "240",
                              "header-align": "left",
                              align: "left"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop: `userInfoExpList[${scope.$index}].userNo`,
                                          rules: _vm.rules.userNo
                                        }
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(scope.row.userNo || "-")
                                          )
                                        ])
                                      ]
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                      _vm.ifEdit
                        ? _c("el-table-column", {
                            attrs: {
                              label: _vm.ifEdit ? "操作" : "",
                              "min-width": "240",
                              "header-align": "left",
                              align: "left"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      scope.row.uid
                                        ? _c(
                                            "el-button",
                                            {
                                              staticClass:
                                                "zwx-button zwx-button-text-26",
                                              attrs: { type: "primary" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.edit(scope.row)
                                                }
                                              }
                                            },
                                            [_vm._v("编辑")]
                                          )
                                        : _vm._e(),
                                      scope.row.uid
                                        ? _c(
                                            "el-button",
                                            {
                                              staticClass:
                                                "zwx-button zwx-button-text-26",
                                              attrs: { type: "primary" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.$system.msgbox(
                                                    scope.row,
                                                    "提示",
                                                    "确定要重置密码吗？",
                                                    "确定",
                                                    "取消",
                                                    _vm.resetPassword
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("初始化密码")]
                                          )
                                        : _vm._e(),
                                      _vm.user.isAdmin &&
                                      !scope.row.isAdmin &&
                                      _vm.user.uid != scope.row.uid
                                        ? _c(
                                            "el-button",
                                            {
                                              staticClass:
                                                "zwx-button zwx-button-text-26",
                                              attrs: { type: "danger" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.$system.msgbox(
                                                    scope.row,
                                                    "提示",
                                                    "确定要删除吗？",
                                                    "确定",
                                                    "取消",
                                                    _vm.deleteUser
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("删除")]
                                          )
                                        : _vm._e()
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2539229014
                            )
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("AdminAddDialog", {
        ref: "adminAddDialog",
        attrs: {
          sexList: _vm.sexList,
          cardList: _vm.cardList,
          techServiceBasic: _vm.techServiceBasic
        },
        on: {
          loading: v => _vm.$emit("loading", v),
          update: function($event) {
            return _vm.$emit("update")
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }