var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-card",
    { staticClass: "card" },
    [
      _c(
        "div",
        { staticClass: "clearfix", attrs: { slot: "header" }, slot: "header" },
        [
          _c("span", { staticClass: "card-header-title" }, [
            _vm._v("报名中培训班")
          ]),
          _c(
            "el-button",
            {
              staticClass: "view-more",
              attrs: { type: "text" },
              on: { click: _vm.viewMore }
            },
            [
              _c("span", [_vm._v("查看更多")]),
              _c("i", { staticClass: "el-icon-arrow-right el-icon--right" })
            ]
          )
        ],
        1
      ),
      _vm._l(_vm.items, function(item) {
        return _c("div", { key: item.uuid, staticClass: "item" }, [
          _c(
            "div",
            {
              staticClass: "left",
              style: {
                backgroundImage: `url(${_vm.file}${item.activityCoverPath})`
              }
            },
            [
              _c("div", { staticClass: "label" }, [_vm._v("已报名")]),
              _c("div", { staticClass: "value-wrapper" }, [
                _c("span", { staticClass: "value" }, [
                  _vm._v(_vm._s(item.traineeListCount || 0))
                ]),
                _c("span", { staticClass: "unit" }, [_vm._v("人")])
              ])
            ]
          ),
          _c("div", { staticClass: "right" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v(_vm._s(item.trainingActivityName || "-"))
            ]),
            item.registStartTime
              ? _c("div", { staticClass: "time" }, [
                  _vm._v(
                    "报名时间：" +
                      _vm._s(item.registStartTime?.split(" ")[0]) +
                      "至" +
                      _vm._s(item.registEndTime?.split(" ")[0])
                  )
                ])
              : _vm._e(),
            item.offLineStartTime
              ? _c("div", { staticClass: "time" }, [
                  _vm._v(
                    "线下培训：" +
                      _vm._s(item.offLineStartTime?.split(" ")[0]) +
                      "至" +
                      _vm._s(item.offLineEndTime?.split(" ")[0])
                  )
                ])
              : _vm._e()
          ])
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }