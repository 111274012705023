<template>
  <div style="margin: 0;padding-bottom: 0px;">
    <base-headline title="行政处罚记录" class="no-top no-bottom">
      <el-button v-if="ifEdit" class="zwx-button zwx-button-icontext-28" icon="el-icon-plus" @click="addInfo">添加</el-button>
    </base-headline>
    <div style="max-width:9999px;">
      <el-form>
        <el-form-item label="" label-width="0" prop="userInfoExpList" style="margin-bottom:0 !important;">
          <el-table class="zwx-table" :data="configList" border stripe tooltip-effect="light">
            <el-table-column prop="punishCode" label="行政处罚决定文书号" width="240" header-align="center" align="center">
              <template slot-scope="scope">
                <el-form-item :prop="`userInfoExpList[${scope.$index}].userName`" :rules="rules.userName">
                  <!-- <el-input class="zwx-input" placeholder="请输入" style="width: 100% !important;" v-model.trim="scope.row.userName" maxlength="50" clearable /> -->
                  <span>{{ scope.row.punishCode || '-' }}</span>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="punishOrgan" label="处罚机关" width="240" header-align="center" align="center">
              <template slot-scope="scope">
                <el-form-item :prop="`userInfoExpList[${scope.$index}].userName`" :rules="rules.userName">
                  <!-- <el-input class="zwx-input" placeholder="请输入" style="width: 100% !important;" v-model.trim="scope.row.userName" maxlength="50" clearable /> -->
                  <span>{{ scope.row.punishOrgan || '-' }}</span>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="punishDate" label="处罚决定日期" width="200" header-align="center" align="center">
              <template slot-scope="scope">
                <el-form-item :prop="`userInfoExpList[${scope.$index}].mobileTel`" :rules="rules.mobileTel">
                  <!-- <el-input class="zwx-input" placeholder="请输入" style="width: 100% !important;" v-model.trim="scope.row.mobileTel" maxlength="20" clearable /> -->
                  <span>{{ scope.row.punishDate ? scope.row.punishDate.split(' ')[0] : '-' }}</span>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column v-if="ifEdit" prop="punishRemark" label="处罚说明" width="240" header-align="center" align="center">
              <template slot-scope="scope">
                <el-form-item :prop="`userInfoExpList[${scope.$index}].userNo`" :rules="rules.userNo">
                  <!-- <el-input class="zwx-input" placeholder="请输入" style="width: 100% !important;" v-model.trim="scope.row.userNo" maxlength="50" clearable /> -->
                  <span>{{ scope.row.punishRemark || '-' }}</span>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column v-else prop="punishRemark" label="处罚说明" min-width="240" header-align="left" align="left">
              <template slot-scope="scope">
                <el-form-item :prop="`userInfoExpList[${scope.$index}].userNo`" :rules="rules.userNo">
                  <!-- <el-input class="zwx-input" placeholder="请输入" style="width: 100% !important;" v-model.trim="scope.row.userNo" maxlength="50" clearable /> -->
                  <span>{{ scope.row.punishRemark || '-' }}</span>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column v-if="ifEdit" :label="ifEdit ? '操作' : ''" min-width="240" header-align="left" align="left">
              <template slot-scope="scope">
                <!-- <el-button v-if="scope.row.uid" class="zwx-button zwx-button-text-26" type="primary" @click="$system.msgbox(scope.row, '提示', '确定要重置密码吗？', '确定', '取消', resetPassword)">初始化密码</el-button> -->
                <el-button class="zwx-button zwx-button-text-26" type="danger" @click="$system.msgbox(scope.row, '提示', '确定要删除吗？', '确定', '取消', deleteUser)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-form>
    </div>
    <!-- 添加管理员信息 -->
    <AdminAddDialog ref="adminAddDialog" :techServiceBasic="techServiceBasic" @loading="v => $emit('loading', v)" @update="$emit('update')" />
  </div>
</template>

<script>
import AdminAddDialog from './AdminAddDialog.vue'

export default {
  name: 'PunishRecordList',
  components: {
    AdminAddDialog,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    techServiceBasic: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      api: this.$store.state.api,
      ifEdit: this.$route.params.ifEdit,
      // 校验规则
      rules: {
        userName: [{ required: true, message: '请填写姓名', trigger: ['change', 'blur'] }],
        mobileTel: [
          { required: true, message: '请填写手机号', trigger: ['change', 'blur'] },
          { required: true, validator: this.$validate.mobilePhone, trigger: ['change', 'blur'] },
        ],
        userNo: [{ required: true, message: '请填写账号', trigger: ['change', 'blur'] }],
      },
      deletedUids: [],
      configList: [],
    }
  },
  watch: {
    value: {
      handler(v) {
        this.configList = v || []
      },
      immediate: true,
    },
  },
  created() {},
  mounted() {},
  methods: {
    formatParams() {
      return [
        ...this.configList.map(item => {
          return {
            ...item,
            mobileTel: this.$zwxSm.sm4JsEncrypt(item.mobileTel),
            userNo: this.$zwxSm.sm4JsEncrypt(item.userNo),
          }
        }),
        ...this.deletedUids.map(uid => {
          return { uid, ifDelete: true }
        }),
      ]
    },
    addInfo() {
      if (!this.techServiceBasic.zoneCode) {
        this.$system.msgbox({}, '提示', '请先完善机构信息', '确定', '取消', () => {
          this.$router.push({
            name: 'service_organization.TechServiceBasicEdit',
            query: { rid: this.techServiceBasic.rid },
            params: { rid: this.techServiceBasic.rid },
          })
        })
        return
      }

      // this.configList.push({
      //   userName: undefined,
      //   mobileTel: undefined,
      //   userNo: undefined,
      // })
      // this.$emit('change', this.configList)
      // this.$emit('add')

      this.$refs.adminAddDialog.$refs.dialog.show(true)
    },
    resetPassword(row) {
      this.$emit('loading', true)
      let data = { uid: row.uid }
      this.$system.post(this.api + '/systematic/resetPasswordCan-1', data, true, true, this.resetPasswordSuccess, this.error)
    },
    resetPasswordSuccess(data) {
      if (data.type === '00') {
        this.$system.notify('成功', data.mess, 'success')
        this.$emit('loading', false)
      } else if (data.type === '99') {
        this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
      } else {
        this.$system.notify('错误', data.mess, 'error')
        this.$emit('loading', false)
      }
    },
    deleteUser(row) {
      this.$emit('loading', true)
      let data = {
        rid: row.rid,
      }
      this.$system.post(this.api + '/oh/repository/deleteTdTechnicalPunishRecord-1', data, true, true, this.deleteUserSuccess, this.error)
    },
    deleteUserSuccess(data) {
      if (data.type === '00') {
        this.$system.notify('成功', data.mess, 'success')
        this.$emit('loading', false)
        this.$emit('update')
      } else if (data.type === '99') {
        this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
      } else {
        this.$system.notify('错误', data.mess, 'error')
        this.$emit('loading', false)
      }
    },
    deleteInfo({ $index: index, row }) {
      if (row.uid) {
        this.deletedUids.push(row.uid)
      }
      this.configList.splice(index, 1)
      this.$emit('change', this.configList)
      this.$emit('delete')
    },
    error() {
      this.$system.notify('错误', '网络连接失败', 'error')
      this.$emit('loading', false)
    },
  },
}
</script>
