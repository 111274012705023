var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-dialog",
    {
      ref: "dialog",
      staticClass: "admin-add-dialog",
      attrs: { title: "信息添加" },
      on: { determine: _vm.submit, cancel: _vm.cancel, close: _vm.close }
    },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "el-form",
            {
              ref: "msForm",
              staticClass: "ms-form",
              attrs: {
                model: _vm.msForm,
                rules: _vm.rules,
                "label-position": "right",
                "label-width": "110px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "姓名",
                    prop: "userName",
                    rules: _vm.rules.userName
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "200px !important" },
                    attrs: {
                      placeholder: "请输入",
                      maxlength: "25",
                      clearable: ""
                    },
                    model: {
                      value: _vm.msForm.userName,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.msForm,
                          "userName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "msForm.userName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "手机号",
                    prop: "mobileTel",
                    rules: _vm.rules.mobileTel
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "200px !important" },
                    attrs: {
                      placeholder: "请输入",
                      maxlength: "20",
                      clearable: ""
                    },
                    model: {
                      value: _vm.msForm.mobileTel,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.msForm,
                          "mobileTel",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "msForm.mobileTel"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "账号",
                    prop: "userNo",
                    rules: _vm.rules.userNo
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "200px !important" },
                    attrs: {
                      placeholder: "请输入",
                      maxlength: "25",
                      clearable: ""
                    },
                    model: {
                      value: _vm.msForm.userNo,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.msForm,
                          "userNo",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "msForm.userNo"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "证件类型", prop: "cardType" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "zwx-input",
                      staticStyle: { width: "200px !important" },
                      model: {
                        value: _vm.msForm.cardType,
                        callback: function($$v) {
                          _vm.$set(_vm.msForm, "cardType", $$v)
                        },
                        expression: "msForm.cardType"
                      }
                    },
                    _vm._l(_vm.cardList, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { value: item.value, label: item.label }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "证件号码", prop: "idc" } },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "200px !important" },
                    attrs: {
                      placeholder: "请输入",
                      maxlength: "25",
                      clearable: ""
                    },
                    model: {
                      value: _vm.msForm.idc,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.msForm,
                          "idc",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "msForm.idc"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "性别", prop: "userSex" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "zwx-input",
                      staticStyle: { width: "200px !important" },
                      model: {
                        value: _vm.msForm.userSex,
                        callback: function($$v) {
                          _vm.$set(_vm.msForm, "userSex", $$v)
                        },
                        expression: "msForm.userSex"
                      }
                    },
                    _vm._l(_vm.sexList, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { value: item.value, label: item.label }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }