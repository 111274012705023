<template>
  <div class="card">
    <div class="overview-wrapper">
      <div v-for="(item, idx) in items" :key="idx" class="item">
        <div class="left" :style="{ backgroundImage: `url(${item.image})` }"></div>
        <div class="right">
          <div class="label">{{ item.label }}</div>
          <div class="value">{{ item.value }}</div>
          <div>
            <span class="label2">{{ item.label2 }}</span>
            <span class="value2">{{ item.value2 }}</span>
            <img :src="require(`./images/ic_${item.value2 < 0 ? 'down' : 'up'}.png`)" alt="" class="icon" />
          </div>
        </div>
      </div>
    </div>

    <el-card shadow="never" :style="{ flexGrow: '1', height: '774px' }">
      <div slot="header" class="clearfix">
        <el-dropdown placement="bottom-start" @command="dropdownCommand">
          <span class="el-dropdown-link">
            <span>{{ dropdownItems[dropdownIdx].label }}</span>
            <i class="el-icon-caret-bottom el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(item, idx) in dropdownItems" :key="item.value" :command="idx">{{ item.label }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button class="view-more" type="text" v-show="false">
          <span>查看更多</span>
          <i class="el-icon-arrow-right el-icon--right"></i>
        </el-button>
      </div>

      <div :style="{ display: 'flex', justifyContent: 'flex-end' }">
        <el-select class="zwx-select " popper-class="zwx-select-popper" v-model="type" :multiple="false" collapse-tags placeholder="请选择" @change="changeType">
          <el-option label="本周" :value="1"></el-option>
          <el-option label="本月" :value="2"></el-option>
          <el-option label="本年度" :value="3"></el-option>
        </el-select>
      </div>

      <div class="chart" ref="chart" :style="{ width: '100%', height: '660px' }"></div>
    </el-card>
  </div>
</template>

<script>
function fetchData() {
  this.$emit('loading', true)
  let params = {
    tendencyType: this.dropdownItems[this.dropdownIdx].value,
    dimensionType: this.type,
  }
  this.$system.postJson(
    this.api + '/training/platform/getAgencyHomeCountTendency-1',
    params,
    true,
    true,
    data => {
      this.$emit('loading', false)
      if (data.type === '00') {
        this.setOption(data)
      } else {
        this.$system.notify('错误', data.mess, 'error')
      }
    },
    () => {
      this.$emit('loading', false)
      this.$system.notify('错误', '网络连接失败', 'error')
    }
  )
}

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      api: this.$store.state.api,
      file: this.$store.state.file,
      dropdownItems: [
        { label: '招生趋势统计图', value: 1, unit: '人' },
        { label: '营收情况趋势图', value: 2, unit: '元' },
      ],
      dropdownIdx: 0,
      type: 1,
    }
  },
  computed: {
    items() {
      const { totalAmountObj, traineeListCountObj, certificateCountObj } = this.data || {}
      return [
        {
          image: require('./images/ic_ysje.png'),
          label: '营收金额',
          value: totalAmountObj?.totalAmount || 0,
          label2: '本月收入：',
          value2: totalAmountObj?.monTotalAmount || 0,
        },
        {
          image: require('./images/ic_zsrs.png'),
          label: '招生人数',
          value: traineeListCountObj?.traineeListCount || 0,
          label2: '本月新增：',
          value2: traineeListCountObj?.monTraineeListCount || 0,
        },
        {
          image: require('./images/ic_zshd.png'),
          label: '证书获得人数',
          value: certificateCountObj?.certificateCount || 0,
          label2: '本月新增：',
          value2: certificateCountObj?.monCertificateCount || 0,
        },
      ]
    },
  },
  methods: {
    dropdownCommand(command) {
      this.dropdownIdx = command
      fetchData.call(this)
    },
    changeType(type) {
      this.type = type
      fetchData.call(this)
    },
    setOption(data) {
      this.chart.setOption({
        grid: {
          left: '5%',
          right: '5%',
        },
        tooltip: {
          trigger: 'axis',
        },
        xAxis: {
          type: 'category',
          axisLine: {
            lineStyle: {
              width: 2,
              color: '#e1e5f4',
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            color: 'rgba(102,102,102,0.97)',
          },
          data: data.xList || [],
        },
        yAxis: {
          name: `单位：${this.dropdownItems[this.dropdownIdx].unit}`,
          nameTextStyle: {
            color: 'rgba(102,102,102,0.97)',
          },
          type: 'value',
          axisLine: {
            show: true,
            lineStyle: {
              width: 2,
              color: '#e1e5f4',
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            color: 'rgba(102,102,102,0.97)',
          },
          splitLine: {
            lineStyle: {
              width: 2,
              color: '#e1e5f4',
              type: 'dashed',
            },
          },
        },
        series: [
          {
            type: 'line',
            symbol: 'circle',
            itemStyle: {
              color: '#ffa920',
              borderColor: '#ffa920',
              borderWidth: 6,
              borderType: 'dashed',
            },
            data: data.yList || [],
          },
        ],
      })
    },
  },
  mounted() {
    this.chart = this.$echarts.init(this.$refs.chart)
    fetchData.call(this)
  },
}
</script>

<style lang="less" scoped>
.card {
  display: flex;
  flex-direction: column;
  width: calc(100% - 24% - 24px - 24%);
  min-height: 950px;
  background: #ffffff;
  border-radius: 2px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12), 0px 0px 6px 0px rgba(0, 0, 0, 0.04);
}
.overview-wrapper {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  align-items: center;
  padding: 44px 24px 44px;
  .item {
    display: flex;
    + .item {
      margin-left: 38px;
    }
    .left {
      flex-shrink: 0;
      width: 87px;
      height: 87px;
      margin-right: 24px;
      background-size: contain;
    }
    .right {
      display: flex;
      flex-direction: column;
      .label {
        font-size: 16px;
        //font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
        color: #777f89;
        line-height: 16px;
      }
      .value {
        margin: 14px 0 10px;
        font-size: 26px;
        //font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
        color: #1f2d3d;
        line-height: 26px;
      }
      .label2,
      .value2 {
        font-size: 14px;
        //font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
        color: #777f89;
        line-height: 14px;
      }
      .icon {
        margin: 0 0 2px 4px;
      }
    }
  }
}
/deep/ .zwx-select .el-input__inner {
  width: 100px !important;
}
</style>
