<template>
  <el-card class="card">
    <div slot="header" class="clearfix">
      <span class="card-header-title">便捷管理</span>
    </div>
    <div v-for="item in items" :key="item.label" class="item">
      <img class="icon" :src="require(`./images/${item.icon}`)" />
      <div class="title-wrapper">
        <div class="title">{{ item.label }}</div>
        <div class="value-wrapper">
          <span class="value">{{ item.value }}</span>
          <span class="unit">{{ item.unit }}</span>
        </div>
      </div>
      <el-button class="view-more" type="text" @click="item.action">
        <span>去查看</span>
        <i class="el-icon-arrow-right el-icon--right"></i>
      </el-button>
    </div>
  </el-card>
</template>

<script>
import dayjs from 'dayjs'
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  computed: {
    items() {
      return [
        {
          icon: 'ic_zsdq.png',
          label: '60天内证书即将到期',
          value: this.data?.expireCertificateCount || '0',
          unit: '人',
          action: () => {
            this.$router.push({
              name: 'StudentHours',
              query: {
                certificateStartTime: dayjs().format('YYYY-MM-DD 00:00:00'),
                certificateEndTime: dayjs()
                  .add(60, 'day')
                  .format('YYYY-MM-DD 00:00:00'),
              },
            })
          },
        },
        {
          icon: 'ic_kswtg.png',
          label: '考试未通过',
          value: this.data?.examUnPassCount || '0',
          unit: '人',
          action: () => {
            this.$router.push({
              name: 'StudentHours',
              query: {
                examResultType: 0,
              },
            })
          },
        },
        {
          icon: 'ic_dks.png',
          label: '学习完成待考试',
          value: this.data?.waitExamCount || '0',
          unit: '人',
          action: () => {
            this.$router.push({
              name: 'StudentHours',
              query: {
                studyState: 2,
                examResultType: 3,
              },
            })
          },
        },
      ]
    },
  },
}
</script>

<style lang="less" scoped>
.card {
  // flex-grow: 1;
  // min-height: 389px;
  flex-shrink: 0;
  height: 40%;
  background: #ffffff;
  border-radius: 2px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12), 0px 0px 6px 0px rgba(0, 0, 0, 0.04);
}
.item {
  display: flex;
  align-items: center;
  padding: 20px;
  background: #f5fafc;
  + .item {
    margin-top: 16px;
  }
  .icon {
    margin-right: 22px;
    width: 50px;
    height: 50px;
  }
  .title-wrapper {
    flex-grow: 1;
    .title {
      margin-bottom: 12px;
      font-size: 16px;
      //font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
      font-weight: Regular;
      color: #191919;
      line-height: 16px;
    }
    .value-wrapper {
      display: flex;
    }
    .value {
      font-size: 20px;
      //font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
      font-weight: Regular;
      color: #2a50d7;
      line-height: 16px;
    }
    .unit {
      margin-left: 4px;
      font-size: 16px;
      //font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
      font-weight: Regular;
      color: #2a50d7;
      line-height: 16px;
    }
  }
}
</style>
