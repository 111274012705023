var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-card",
    { staticClass: "card" },
    [
      _c(
        "div",
        { staticClass: "clearfix", attrs: { slot: "header" }, slot: "header" },
        [_c("span", { staticClass: "card-header-title" }, [_vm._v("便捷管理")])]
      ),
      _vm._l(_vm.items, function(item) {
        return _c(
          "div",
          { key: item.label, staticClass: "item" },
          [
            _c("img", {
              staticClass: "icon",
              attrs: { src: require(`./images/${item.icon}`) }
            }),
            _c("div", { staticClass: "title-wrapper" }, [
              _c("div", { staticClass: "title" }, [_vm._v(_vm._s(item.label))]),
              _c("div", { staticClass: "value-wrapper" }, [
                _c("span", { staticClass: "value" }, [
                  _vm._v(_vm._s(item.value))
                ]),
                _c("span", { staticClass: "unit" }, [_vm._v(_vm._s(item.unit))])
              ])
            ]),
            _c(
              "el-button",
              {
                staticClass: "view-more",
                attrs: { type: "text" },
                on: { click: item.action }
              },
              [
                _c("span", [_vm._v("去查看")]),
                _c("i", { staticClass: "el-icon-arrow-right el-icon--right" })
              ]
            )
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }