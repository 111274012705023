<template>
  <div class="basic-detail-page">
    <div class="title">
      <span style="margin-right:16px">{{ techServiceBasic.organName }}</span>
      <el-button v-if="ifEdit == 1" class="zwx-button zwx-button-icontext-28" icon="el-icon-edit" @click="edit">编辑</el-button>
    </div>
    <hr class="hr" />
    <el-row style="padding:20px 20px;">
      <el-col :span="8">
        <el-descriptions class="basic-descriptions" :column="1">
          <el-descriptions-item class="info-item"  label="社会信用代码" content-class-name="tech-value-class">{{ techServiceBasic.creditCode }}</el-descriptions-item>
          <el-descriptions-item class="info-item"  label="机构类型">{{  helper[techServiceBasic.organType] }}</el-descriptions-item>
          <el-descriptions-item class="info-item"  label="经营地区">{{ techServiceBasic.zoneName }}</el-descriptions-item>
          <el-descriptions-item class="info-item"  label="经营地址">{{ techServiceBasic.workAddr }}</el-descriptions-item>
          <el-descriptions-item class="info-item" v-if="isTown" label="传真">{{ techServiceBasic.fax }}</el-descriptions-item>
          <el-descriptions-item class="info-item" v-if="isTown" label="邮编">{{ techServiceBasic.zipCode }}</el-descriptions-item>

          <!-- <el-descriptions-item class="info-item"  label="单位类型">{{ { 1: '事业单位', 2: '国有企业', 3: '民营企业' }[techServiceBasic.organType] }}</el-descriptions-item> -->
        </el-descriptions>
      </el-col>
      <el-col :span="8">
        <el-descriptions class="basic-descriptions" :column="1">
          <el-descriptions-item class="info-item" v-if="isTown" label="法定代表人职务">{{ techServiceBasic.legalPost }}</el-descriptions-item>
          <el-descriptions-item class="info-item"  label="法定联系人">{{ techServiceBasic.legalPerson }}</el-descriptions-item>
          <el-descriptions-item class="info-item"  label="法定代表人电话">{{ $zwxSm.sm4JsDecrypt(techServiceBasic.telephone) }}</el-descriptions-item>
          <el-descriptions-item class="info-item"  label="联系人">{{ techServiceBasic.linkMan }}</el-descriptions-item>
          <el-descriptions-item class="info-item"  label="联系人手机">{{ $zwxSm.sm4JsDecrypt(techServiceBasic.linkMobile) }}</el-descriptions-item>
        </el-descriptions>
      </el-col>
      <el-col :span="8" class="basic-descriptions last-basic-descriptions" v-if="!isTown">
        <el-descriptions :column="1">
          <el-descriptions-item class="info-item"  label="电子邮箱">{{ techServiceBasic.email }}</el-descriptions-item>
       
          <el-descriptions-item class="info-item"  label="营业执照">
            <div :class="imgClass(techServiceBasic.licenseName)"></div>
            <div class="ms-folder-content pointer" @click="openFilePreview(techServiceBasic.licenseName, techServiceBasic.licensePath)">
              {{ techServiceBasic.licenseName }}
            </div>
          </el-descriptions-item>
          <el-descriptions-item class="info-item"  label="员工证明">
            <div :class="imgClass(techServiceBasic.employerName)"></div>
            <div class="ms-folder-content pointer" @click="openFilePreview(techServiceBasic.employerName, techServiceBasic.employerPath)">
              {{ techServiceBasic.employerName }}
            </div>
          </el-descriptions-item>
          <el-descriptions-item class="info-item"  label="备案信息">
            <div v-if="techServiceBasic.technicalServiceOrganAnnexExpList">
              <div v-for="item in techServiceBasic.technicalServiceOrganAnnexExpList" :key="item.rid" style="margin-bottom: 4px;display: flex;">
                <div :class="imgClass(item.annexName)"></div>
                <div class="ms-folder-content pointer" @click="openFilePreview(item.annexName, item.annexPath)">
                  {{ item.annexName }}
                </div>
              </div>
              
            </div>
          </el-descriptions-item>
        </el-descriptions>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'TechBasicDetail',
  props: {
    techServiceBasic: { type: Object, default: () => {} },
    ifEdit: { type: Number, default: 2 },
    isTown:{type:Boolean,default:()=>{
      return false
    }}
  },
  data() {
    return {}
  },
  created() {},
  mounted() {},
  computed:{
    helper(){
      return   { 1: '服务机构', 2: process.env.VUE_APP_HELPER }
    }
    
  },
  methods: {
    edit() {
      if(this.isTown){
        this.$router.push({ name: 'TownServiceBasicEdit', params: { rid: this.techServiceBasic.unitUid } })
      }else{
        this.$router.push({ name: 'service_organization.TechServiceBasicEdit', params: { rid: this.techServiceBasic.unitUid } })
      }
      
    },
    imgClass(fileName) {
      return fileName && (fileName.lastIndexOf('jpg') >= 0 || fileName.lastIndexOf('pdf') >= 0 || fileName.lastIndexOf('png') >= 0) ? 'basic-img-div-' + fileName.substring(fileName.lastIndexOf('.') + 1) : ''
    },
    /**
     * 单个文件预览
     */
    openFilePreview(fileName, filePath) {
      let previewFileList = []
      let item = {
        fileName: fileName,
        filePath: filePath,
      }
      previewFileList.push(item)
      top.postMessage(
        {
          handlerType: 'previewFile',
          params: {
            fileList: previewFileList,
            index: 0,
          },
        },
        '*'
      )
    },
  },
}
</script>

<style lang="less" scoped>
.info-item::before {
  content: '';
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: #ffaf92;
  border-radius: 50%;
  margin-right: 5px;
  vertical-align: middle;
}
.basic-descriptions /deep/ .el-descriptions-item__label::before {
  content: '';
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: #ffaf92;
  border-radius: 50%;
  margin-right: 5px;
  vertical-align: middle;
}
.basic-detail-page {
  margin: 0 30px;
  .title {
    margin: 2px 0px 8px 0;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
    font-family: Harmony Medium;
    font-weight: 500;
    text-align: left;
    color: #191919;
    display: flex;
    align-items: center;
  }
}

.hr {
  height: 1px;
  border: 1px dashed #d5d9e0;
}
</style>

<style lang="less">
.basic-descriptions .el-descriptions-item__content {
  color: #191919;
}
.pointer {
  cursor: pointer;
}

.basic-img-div-pdf {
  width: 16px;
  height: 16px;
  top: 3px;
  left: -2px;
  position: relative;
  background: url('~@/assets/ico/file/icon_pdf.svg') left 0px no-repeat;
}
.basic-img-div-jpg {
  width: 16px;
  height: 16px;
  top: 3px;
  left: -2px;
  position: relative;
  background: url('~@/assets/ico/file/icon_jpg.svg') left 0px no-repeat;
}
.basic-img-div-png {
  width: 16px;
  height: 16px;
  top: 3px;
  left: -2px;
  position: relative;
  background: url('~@/assets/ico/file/icon_jpg.svg') left 0px no-repeat;
}
</style>
