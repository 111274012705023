import { render, staticRenderFns } from "./AdminAddDialog.vue?vue&type=template&id=251b9c33&scoped=true"
import script from "./AdminAddDialog.vue?vue&type=script&lang=js"
export * from "./AdminAddDialog.vue?vue&type=script&lang=js"
import style0 from "./AdminAddDialog.vue?vue&type=style&index=0&id=251b9c33&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "251b9c33",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\product-yangzhou\\view\\ms-vue-occupational-health\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('251b9c33')) {
      api.createRecord('251b9c33', component.options)
    } else {
      api.reload('251b9c33', component.options)
    }
    module.hot.accept("./AdminAddDialog.vue?vue&type=template&id=251b9c33&scoped=true", function () {
      api.rerender('251b9c33', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/agency/techServiceBasic/components/AdminAddDialog.vue"
export default component.exports