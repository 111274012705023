<template>
  <base-dialog ref="dialog" class="admin-add-dialog" title="监督检查记录添加" @determine="submit" @cancel="cancel" @close="close">
    <div class="content">
      <el-form class="zwx-form" ref="msForm" :model="msForm" :rules="rules" label-position="right" label-width="121px">
        <el-form-item label="监督检查日期：" prop="superviseDate" :rules="rules.superviseDate">
          <!-- <el-input class="zwx-input" placeholder="请输入" style="width: 300px !important;" v-model.trim="msForm.superviseDate" maxlength="50" clearable /> -->
          <el-date-picker class="zwx-date-picker" popper-class="zwx-date-picker-popper" v-model="msForm.superviseDate" format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date" placeholder="请选择" style="width: 300px !important;" />
        </el-form-item>
        <el-form-item label="监督员：" prop="superviseUser" :rules="rules.superviseUser">
          <el-input class="zwx-input" placeholder="请输入" style="width: 300px !important;" v-model.trim="msForm.superviseUser" maxlength="20" clearable />
        </el-form-item>
        <el-form-item label="检查机构：" prop="superviseOrgan" :rules="rules.superviseOrgan">
          <el-input class="zwx-input" placeholder="请输入" style="width: 300px !important;" v-model.trim="msForm.superviseOrgan" maxlength="50" clearable />
        </el-form-item>
        <el-form-item label="任务类型：" prop="superviseType" :rules="rules.superviseType">
          <!-- <el-input class="zwx-input" placeholder="请输入" style="width: 300px !important;" v-model.trim="msForm.superviseType" maxlength="50" clearable /> -->
          <el-select class="zwx-select" popper-class="zwx-select-popper" v-model="msForm.superviseType" clearable value-key="value" placeholder="请选择" style="width: 300px !important;">
            <el-option label="日常监督检查" :value="1"></el-option>
            <el-option label="国家“双随机抽查”" :value="2"></el-option>
            <el-option label="省级“双随机”抽查" :value="3"></el-option>
            <el-option label="任务抽取" :value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="检查结论：" prop="checkResult" :rules="rules.checkResult">
          <!-- <el-input class="zwx-input" placeholder="请输入" style="width: 300px !important;" v-model.trim="msForm.checkResult" maxlength="20" clearable /> -->
          <el-radio-group class="zwx-radio-group" v-model="msForm.checkResult">
            <el-radio class="zwx-radio" :label="1">合格</el-radio>
            <el-radio class="zwx-radio" :label="0">不合格</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="监督说明：" prop="superviseRemark" :rules="rules.superviseRemark">
          <el-input class="zwx-input" placeholder="请输入" style="width: 300px !important;" v-model.trim="msForm.superviseRemark" maxlength="50" clearable />
        </el-form-item>
      </el-form>
    </div>
  </base-dialog>
</template>

<script>
export default {
  props: {
    techServiceBasic: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      api: this.$store.state.api,
      users: this.$store.state.users,
      rules: {
        // mobileTel: [
        //   { required: true, message: '请填写手机号', trigger: ['change', 'blur'] },
        //   { required: true, validator: this.$validate.mobilePhone, trigger: ['change', 'blur'] },
        // ],
        superviseDate: [{ required: true, message: '请填写监督检查日期', trigger: ['change', 'blur'] }],
        superviseUser: [{ required: true, message: '请填写监督员', trigger: ['change', 'blur'] }],
        superviseOrgan: [{ required: true, message: '请填写检查机构', trigger: ['change', 'blur'] }],
        superviseType: [{ required: true, message: '请选择任务类型', trigger: ['change', 'blur'] }],
        checkResult: [{ required: true, message: '请填写检查结论', trigger: ['change', 'blur'] }],
        superviseRemark: [{ required: true, message: '请填写监督说明', trigger: ['change', 'blur'] }],
      },
      msForm: {
        superviseDate: undefined,
        superviseUser: undefined,
        superviseOrgan: undefined,
        superviseType: undefined,
        checkResult: undefined,
        superviseRemark: undefined,
      },
    }
  },
  methods: {
    cancel() {
      this.$refs.dialog.show(false)
    },
    close() {
      this.$refs.msForm.resetFields()
    },
    submit() {
      this.$refs.msForm.validate(valid => {
        if (valid) {
          this.saveData()
        }
      })
    },
    saveData() {
      this.$emit('loading', true)
      let data = {
        unitUid: this.techServiceBasic.unitUid,
        ...this.msForm,
        // tsUserInfoExp: {
        //   mobileTel: this.$zwxSm.sm4JsEncrypt(this.msForm.mobileTel),
        //   userNo: this.$zwxSm.sm4JsEncrypt(this.msForm.userNo),
        // },
      }
      this.$system.postJson(
        this.api + '/oh/repository/addOrModifyTdTechnicalSuperviseRecord-1',
        data,
        true,
        false,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.$refs.dialog.show(false)
            this.$system.notify('成功', data.mess, 'success')
            this.$emit('update')
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
            this.$emit('loading', false)
          } else {
            this.$notify.error({ title: '错误', message: data.mess })
            this.$emit('loading', false)
          }
        },
        () => {
          this.$emit('loading', false)
          this.$notify.error({ title: '错误', message: '网络连接失败' })
        }
      )
    },
  },
}
</script>

<style lang="less" scoped>
.content {
  padding-bottom: 12px;
  box-sizing: border-box;
}
.admin-add-dialog {
  /deep/ .el-dialog {
    width: 500px !important;
    min-width: 500px !important;
  }
}
/deep/ .zwx-form {
  // padding-right: 0 !important;
  .el-form-item {
    margin-right: 0 !important;
    margin-bottom: 16px !important;
  }
}
/deep/ .zwx-form {
  .zwx-select {
    width: 300px;
    .el-input__inner {
      width: 300px !important;
    }
  }
}
</style>
