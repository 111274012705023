<template>
  <div class="container">
    <div class="page-wrapper">
      <div class="left">
        <LeftTop :data="data" :style="{ marginBottom: '12px' }" />
        <LeftBottom :data="data" />
      </div>
      <MiddleCard :data="data" :style="{ margin: '0 12px' }" />
      <div class="right">
        <RightTop :data="data" :style="{ marginBottom: '12px' }" />
        <RightBottom :data="data" />
      </div>
    </div>
  </div>
</template>

<script>
import LeftTop from './LeftTop.vue'
import LeftBottom from './LeftBottom.vue'
import MiddleCard from './MiddleCard.vue'
import RightTop from './RightTop.vue'
import RightBottom from './RightBottom.vue'

function fetchData() {
  this.$emit('loading', true)
  let params = {}
  this.$system.post(
    this.api + '/training/platform/getAgencyHomeCountData-1',
    params,
    true,
    true,
    data => {
      this.$emit('loading', false)
      if (data.type === '00') {
        this.data = data
      } else {
        this.$system.notify('错误', data.mess, 'error')
      }
    },
    () => {
      this.$emit('loading', false)
      this.$system.notify('错误', '网络连接失败', 'error')
    }
  )
}

export default {
  components: {
    LeftTop,
    LeftBottom,
    MiddleCard,
    RightTop,
    RightBottom,
  },
  data() {
    return {
      api: this.$store.state.api,
      file: this.$store.state.file,
      data: null,
    }
  },
  mounted() {
    fetchData.call(this)
  },
}
</script>

<style lang="less" scoped>
/deep/ .el-card__header {
  padding: 14px 16px;
}
/deep/ .el-card__body {
  padding: 14px 16px;
}
.container {
  height: calc(100vh - 44px) !important;
  overflow: auto;
}
.page-wrapper {
  display: flex;
  padding: 12px;
  min-width: 1656px;
  min-height: 100%;
  max-height: ~'max(100%, 974px)';
}
.left {
  display: flex;
  flex-direction: column;
  width: 24%;
}
.right {
  display: flex;
  flex-direction: column;
  width: 24%;
}
/deep/ .card-header-title {
  font-size: 16px;
  color: #1f2d3d;
  line-height: 16px;
}
/deep/ .view-more {
  float: right;
  padding: 3px 0;
  color: #2a50d7;
}
</style>
