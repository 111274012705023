import { render, staticRenderFns } from "./TechBasicDetail.vue?vue&type=template&id=58642000&scoped=true"
import script from "./TechBasicDetail.vue?vue&type=script&lang=js"
export * from "./TechBasicDetail.vue?vue&type=script&lang=js"
import style0 from "./TechBasicDetail.vue?vue&type=style&index=0&id=58642000&lang=less&scoped=true"
import style1 from "./TechBasicDetail.vue?vue&type=style&index=1&id=58642000&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58642000",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\product-yangzhou\\view\\ms-vue-occupational-health\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('58642000')) {
      api.createRecord('58642000', component.options)
    } else {
      api.reload('58642000', component.options)
    }
    module.hot.accept("./TechBasicDetail.vue?vue&type=template&id=58642000&scoped=true", function () {
      api.rerender('58642000', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/agency/techServiceBasic/components/TechBasicDetail.vue"
export default component.exports