var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "page-wrapper" },
      [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c("LeftTop", {
              style: { marginBottom: "12px" },
              attrs: { data: _vm.data }
            }),
            _c("LeftBottom", { attrs: { data: _vm.data } })
          ],
          1
        ),
        _c("MiddleCard", {
          style: { margin: "0 12px" },
          attrs: { data: _vm.data }
        }),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c("RightTop", {
              style: { marginBottom: "12px" },
              attrs: { data: _vm.data }
            }),
            _c("RightBottom", { attrs: { data: _vm.data } })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }