var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-dialog",
    {
      ref: "dialog",
      staticClass: "admin-add-dialog",
      attrs: { title: "行政处罚记录添加" },
      on: { determine: _vm.submit, cancel: _vm.cancel, close: _vm.close }
    },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "el-form",
            {
              ref: "msForm",
              staticClass: "zwx-form",
              attrs: {
                model: _vm.msForm,
                rules: _vm.rules,
                "label-position": "right",
                "label-width": "151px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "行政处罚决定文书号：",
                    prop: "punishCode",
                    rules: _vm.rules.punishCode
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "300px !important" },
                    attrs: {
                      placeholder: "请输入",
                      maxlength: "20",
                      clearable: ""
                    },
                    model: {
                      value: _vm.msForm.punishCode,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.msForm,
                          "punishCode",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "msForm.punishCode"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "处罚机关：",
                    prop: "punishOrgan",
                    rules: _vm.rules.punishOrgan
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "300px !important" },
                    attrs: {
                      placeholder: "请输入",
                      maxlength: "50",
                      clearable: ""
                    },
                    model: {
                      value: _vm.msForm.punishOrgan,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.msForm,
                          "punishOrgan",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "msForm.punishOrgan"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "处罚决定日期：",
                    prop: "punishDate",
                    rules: _vm.rules.punishDate
                  }
                },
                [
                  _c("el-date-picker", {
                    staticClass: "zwx-date-picker",
                    staticStyle: { width: "300px !important" },
                    attrs: {
                      "popper-class": "zwx-date-picker-popper",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      type: "date",
                      placeholder: "请选择"
                    },
                    model: {
                      value: _vm.msForm.punishDate,
                      callback: function($$v) {
                        _vm.$set(_vm.msForm, "punishDate", $$v)
                      },
                      expression: "msForm.punishDate"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "处罚说明：",
                    prop: "punishRemark",
                    rules: _vm.rules.punishRemark
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-textarea",
                    staticStyle: { width: "300px !important" },
                    attrs: {
                      type: "textarea",
                      rows: 3,
                      placeholder: "请输入",
                      maxlength: "50",
                      clearable: ""
                    },
                    model: {
                      value: _vm.msForm.punishRemark,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.msForm,
                          "punishRemark",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "msForm.punishRemark"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }