<template>
  <div class="card">
    <div class="title">{{ agency.unitName }}</div>
    <div class="code">社会信用代码：{{ agency.creditCode }}</div>
    <div class="container">
      <div class="label">联系人/联系电话：</div>
      <div class="value">{{ agency.linkPerson }} {{ $zwxSm.sm4JsDecrypt(agency.unitContact) }}</div>
      <div class="label">负责区域：</div>
      <!--
      <div class="value" v-for="(item, idx) in agency.areaAndTrainingAgencyRelationExpList" :key="idx">{{ item.busZoneCodeName }}</div>
      -->
      <div class="value">{{ agency.areaAndTrainingAgencyRelationExpList.map(v => v.busZoneCodeName).join('，') || '-' }}</div>
      <el-button class="view-detail" type="text" @click="viewDetail(agency)">
        <span>查看详情</span>
        <i class="el-icon-arrow-right el-icon--right"></i>
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  computed: {
    agency() {
      return this.data?.tdTrainingAgencyExp || { areaAndTrainingAgencyRelationExpList: [] }
    },
  },
  methods: {
    viewDetail(row) {
      this.$router.push({ name: 'trainingInstitutionManagement.detail', params: { uuid: row.uuid } })
    },
  },
}
</script>

<style lang="less" scoped>
.card {
  display: flex;
  flex-direction: column;
  // align-items: flex-start;
  flex-shrink: 0;
  height: 40%;
  padding: 32px 24px 24px;
  // flex-grow: 1;
  // min-height: 389px;
  background: #ffffff;
  border-radius: 2px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12), 0px 0px 6px 0px rgba(0, 0, 0, 0.04);
  background-size: cover;
  background-position: center;
  background-image: url('~@/views/trainingInstitutionHome/images/card_448.png');
}
.title {
  margin-bottom: 10px;
  font-size: 26px;
  //font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
  font-weight: bold;
  color: rgba(31, 45, 61, 0.97);
  line-height: 32px;
}
.code {
  margin-bottom: 16px;
  font-size: 16px;
  //font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
  font-weight: Regular;
  color: #777f89;
  line-height: 23px;
}
.container {
  overflow: auto;
  padding: 20px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.72) 95%);
  border-radius: 4px;
  box-shadow: 0px 2px 10px 0px rgba(209, 219, 246, 0.69), -2px -3px 1px 0px #ffffff inset;
  .label {
    font-size: 16px;
    //font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
    font-weight: Regular;
    color: #777f89;
    line-height: 20px;
  }
  .value {
    margin: 8px 0 16px;
    font-size: 16px;
    //font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
    font-weight: Regular;
    color: #1f2d3d;
    line-height: 20px;
  }
}
.view-detail {
  padding: 3px 0;
  color: #2a50d7;
}
</style>
