<template>
  <base-dialog ref="dialog" class="admin-add-dialog" title="行政处罚记录添加" @determine="submit" @cancel="cancel" @close="close">
    <div class="content">
      <el-form class="zwx-form" ref="msForm" :model="msForm" :rules="rules" label-position="right" label-width="151px">
        <el-form-item label="行政处罚决定文书号：" prop="punishCode" :rules="rules.punishCode">
          <el-input class="zwx-input" placeholder="请输入" style="width: 300px !important;" v-model.trim="msForm.punishCode" maxlength="20" clearable />
        </el-form-item>
        <el-form-item label="处罚机关：" prop="punishOrgan" :rules="rules.punishOrgan">
          <el-input class="zwx-input" placeholder="请输入" style="width: 300px !important;" v-model.trim="msForm.punishOrgan" maxlength="50" clearable />
        </el-form-item>
        <el-form-item label="处罚决定日期：" prop="punishDate" :rules="rules.punishDate">
          <!-- <el-input class="zwx-input" placeholder="请输入" style="width: 300px !important;" v-model.trim="msForm.superviseDate" maxlength="50" clearable /> -->
          <el-date-picker class="zwx-date-picker" popper-class="zwx-date-picker-popper" v-model="msForm.punishDate" format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date" placeholder="请选择" style="width: 300px !important;" />
        </el-form-item>
        <el-form-item label="处罚说明：" prop="punishRemark" :rules="rules.punishRemark">
          <el-input class="zwx-textarea" type="textarea" :rows="3" placeholder="请输入" style="width: 300px !important;" v-model.trim="msForm.punishRemark" maxlength="50" clearable />
        </el-form-item>
      </el-form>
    </div>
  </base-dialog>
</template>

<script>
export default {
  props: {
    techServiceBasic: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      api: this.$store.state.api,
      users: this.$store.state.users,
      rules: {
        // mobileTel: [
        //   { required: true, message: '请填写手机号', trigger: ['change', 'blur'] },
        //   { required: true, validator: this.$validate.mobilePhone, trigger: ['change', 'blur'] },
        // ],
        punishCode: [{ required: true, message: '请填写行政处罚决定文书号', trigger: ['change', 'blur'] }],
        punishOrgan: [{ required: true, message: '请填写处罚机关', trigger: ['change', 'blur'] }],
        punishDate: [{ required: true, message: '请填写处罚决定日期', trigger: ['change', 'blur'] }],
        punishRemark: [{ required: true, message: '请填写处罚说明', trigger: ['change', 'blur'] }],
      },
      msForm: {
        punishCode: undefined,
        punishOrgan: undefined,
        punishDate: undefined,
        punishRemark: undefined,
      },
    }
  },
  methods: {
    cancel() {
      this.$refs.dialog.show(false)
    },
    close() {
      this.$refs.msForm.resetFields()
    },
    submit() {
      this.$refs.msForm.validate(valid => {
        if (valid) {
          this.saveData()
        }
      })
    },
    saveData() {
      this.$emit('loading', true)
      let data = {
        unitUid: this.techServiceBasic.unitUid,
        ...this.msForm,
        // tsUserInfoExp: {
        //   mobileTel: this.$zwxSm.sm4JsEncrypt(this.msForm.mobileTel),
        //   userNo: this.$zwxSm.sm4JsEncrypt(this.msForm.userNo),
        // },
      }
      this.$system.postJson(
        this.api + '/oh/repository/addOrModifyTdTechnicalPunishRecord-1',
        data,
        true,
        false,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.$refs.dialog.show(false)
            this.$system.notify('成功', data.mess, 'success')
            this.$emit('update')
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
            this.$emit('loading', false)
          } else {
            this.$notify.error({ title: '错误', message: data.mess })
            this.$emit('loading', false)
          }
        },
        () => {
          this.$emit('loading', false)
          this.$notify.error({ title: '错误', message: '网络连接失败' })
        }
      )
    },
  },
}
</script>

<style lang="less" scoped>
.content {
  padding-bottom: 12px;
  box-sizing: border-box;
}
.admin-add-dialog {
  /deep/ .el-dialog {
    width: 500px !important;
    min-width: 500px !important;
  }
}
/deep/ .zwx-form {
  // padding-right: 0 !important;
  .el-form-item {
    margin-right: 0 !important;
    margin-bottom: 16px !important;
  }
}
/deep/ .zwx-form {
  .zwx-select {
    width: 300px;
    .el-input__inner {
      width: 300px !important;
    }
  }
}
</style>
