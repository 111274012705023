// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/ico/file/icon_pdf.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/ico/file/icon_jpg.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".basic-descriptions .el-descriptions-item__content {\n  color: #191919;\n}\n.pointer {\n  cursor: pointer;\n}\n.basic-img-div-pdf {\n  width: 16px;\n  height: 16px;\n  top: 3px;\n  left: -2px;\n  position: relative;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") left 0px no-repeat;\n}\n.basic-img-div-jpg {\n  width: 16px;\n  height: 16px;\n  top: 3px;\n  left: -2px;\n  position: relative;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") left 0px no-repeat;\n}\n.basic-img-div-png {\n  width: 16px;\n  height: 16px;\n  top: 3px;\n  left: -2px;\n  position: relative;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") left 0px no-repeat;\n}\n", ""]);
// Exports
module.exports = exports;
