// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/views/trainingInstitutionHome/images/card_448.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".card[data-v-5b870ba5] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  -ms-flex-negative: 0;\n      flex-shrink: 0;\n  height: 40%;\n  padding: 32px 24px 24px;\n  background: #ffffff;\n  border-radius: 2px;\n  -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12), 0px 0px 6px 0px rgba(0, 0, 0, 0.04);\n          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12), 0px 0px 6px 0px rgba(0, 0, 0, 0.04);\n  background-size: cover;\n  background-position: center;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.title[data-v-5b870ba5] {\n  margin-bottom: 10px;\n  font-size: 26px;\n  font-weight: bold;\n  color: rgba(31, 45, 61, 0.97);\n  line-height: 32px;\n}\n.code[data-v-5b870ba5] {\n  margin-bottom: 16px;\n  font-size: 16px;\n  font-weight: Regular;\n  color: #777f89;\n  line-height: 23px;\n}\n.container[data-v-5b870ba5] {\n  overflow: auto;\n  padding: 20px;\n  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.8)), color-stop(95%, rgba(255, 255, 255, 0.72)));\n  background: linear-gradient(180deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.72) 95%);\n  border-radius: 4px;\n  -webkit-box-shadow: 0px 2px 10px 0px rgba(209, 219, 246, 0.69), -2px -3px 1px 0px #ffffff inset;\n          box-shadow: 0px 2px 10px 0px rgba(209, 219, 246, 0.69), -2px -3px 1px 0px #ffffff inset;\n}\n.container .label[data-v-5b870ba5] {\n  font-size: 16px;\n  font-weight: Regular;\n  color: #777f89;\n  line-height: 20px;\n}\n.container .value[data-v-5b870ba5] {\n  margin: 8px 0 16px;\n  font-size: 16px;\n  font-weight: Regular;\n  color: #1f2d3d;\n  line-height: 20px;\n}\n.view-detail[data-v-5b870ba5] {\n  padding: 3px 0;\n  color: #2a50d7;\n}\n", ""]);
// Exports
module.exports = exports;
