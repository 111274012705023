var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card" }, [
    _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.agency.unitName))]),
    _c("div", { staticClass: "code" }, [
      _vm._v("社会信用代码：" + _vm._s(_vm.agency.creditCode))
    ]),
    _c(
      "div",
      { staticClass: "container" },
      [
        _c("div", { staticClass: "label" }, [_vm._v("联系人/联系电话：")]),
        _c("div", { staticClass: "value" }, [
          _vm._v(
            _vm._s(_vm.agency.linkPerson) +
              " " +
              _vm._s(_vm.$zwxSm.sm4JsDecrypt(_vm.agency.unitContact))
          )
        ]),
        _c("div", { staticClass: "label" }, [_vm._v("负责区域：")]),
        _c("div", { staticClass: "value" }, [
          _vm._v(
            _vm._s(
              _vm.agency.areaAndTrainingAgencyRelationExpList
                .map(v => v.busZoneCodeName)
                .join("，") || "-"
            )
          )
        ]),
        _c(
          "el-button",
          {
            staticClass: "view-detail",
            attrs: { type: "text" },
            on: {
              click: function($event) {
                return _vm.viewDetail(_vm.agency)
              }
            }
          },
          [
            _c("span", [_vm._v("查看详情")]),
            _c("i", { staticClass: "el-icon-arrow-right el-icon--right" })
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }